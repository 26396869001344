// Descomentar o código abaixo caso seja necessário usar o Database do Browser
/* var db = new Dexie("DATABASE_NAME");
db.version(1).stores({
	users: "++id,name,login,password"
	// ...add more stores (tables) here...
});
db.open(); */

// Descomentar o código abaixo case seja usado AngularJS (v1.x)
// var app = angular.module("app", ["cgNotify", "ngMask"]);
/* 
angular.module("app", ["cgNotify"]).controller("Controller", [
	"$scope",
	"$sce",
	"$http",
	"notify",
	function($scope, $sce, $http, notify) {
		function init() {}
		init();
	}
]); */

// Descomentar o código abaixo caso seja usado JQuery
/* $(document).ready(function() {}); */

//Clicando num link do menu
$(document).ready(function () {
    $(".navbar-nav .nav-item").click(function (e) {
        e.preventDefault();
        hideMenu($(this));
    });
    $(".navbar-nav .dropdown-item").click(function (e) {
        e.preventDefault();
        hideMenu();
    });
});

hideMenu = function ($element) {
    $(".navbar-nav .nav-item").removeClass("active");
    $element.addClass("active");
    if (!$(this).hasClass("dropdown")) $("#navbarMain").removeClass("show");
};

//Inicializando o envio de emails pelo EmailJS
emailjs.init("user_WYX1rzp7oeK5XY3ifBgxo");
